import { render, staticRenderFns } from "./elevatorVideo.vue?vue&type=template&id=08741430&scoped=true&"
import script from "./elevatorVideo.vue?vue&type=script&lang=js&"
export * from "./elevatorVideo.vue?vue&type=script&lang=js&"
import style0 from "./elevatorVideo.vue?vue&type=style&index=0&id=08741430&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08741430",
  null
  
)

export default component.exports